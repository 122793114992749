import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ParallaxComponent } from '../common';

import AddressComponent from './address';

import './styles.styl';

// TODO: find a better name, it's confusing
export default class ContactComponent extends Component {
  renderHeader() {
    const { wordings } = this.props;

    return (
      <ParallaxComponent index={10}>
        <h1>{wordings.title}</h1>
        <p className="subtitle">
          {wordings.more_info_label}
          {` `}
          <a
            className="underline"
            href={`mailto:${wordings.address_main_email}`}
          >
            {wordings.more_info_link}
          </a>
        </p>
      </ParallaxComponent>
    );
  }

  renderAddresses() {
    const { addresses } = this.props;

    return (
      <div className="address-wrapper">
        {addresses.map(({ description, email, title }) => (
          <AddressComponent
            description={description}
            email={email}
            title={title}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <section className="contact">
        {this.renderHeader()}
        <div className="wrapper">
          <div className="content-wrapper">{this.renderAddresses()}</div>
        </div>
      </section>
    );
  }
}

ContactComponent.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ).isRequired,
  wordings: PropTypes.shape({}).isRequired,
};
