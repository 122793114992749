import React from 'react';
import PropTypes from 'prop-types';

const AddressComponent = ({ title, description, email }) => (
  <address>
    <h3>{title}</h3>
    <span dangerouslySetInnerHTML={{ __html: description }} />
    <a href={`mailto:${email}`}>{email}</a>
  </address>
);

AddressComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default AddressComponent;
