import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import ContactComponent from '../components/contact';
import {
  NavBar,
  Menu,
  Footer,
  Basket,
  pageWithNavigation,
} from '../components/common';

const ContactPage = ({
  data: {
    wordings: {
      fr: { contact },
    },
  },
}) => (
  <Layout title={contact.title} description={contact.description}>
    <NavBar withLogo basketClassName="yellow" />
    <Basket />
    <Menu />
    <ContactComponent
      addresses={[
        {
          title: contact.address_distribution_title,
          description: contact.address_distribution_text,
          email: contact.address_distribution_email,
        },
        {
          title: contact.address_main_title,
          description: contact.address_main_text,
          email: contact.address_main_email,
        },
        {
          title: contact.address_support_title,
          description: contact.address_support_text,
          email: contact.address_support_email,
        },
      ]}
      wordings={contact}
    />
    <Footer />
  </Layout>
);

export default pageWithNavigation(ContactPage);

export const query = graphql`
  query ContactPage {
    wordings {
      fr {
        contact {
          page_title
          description
          title
          more_info_label
          more_info_link
          address_distribution_title
          address_distribution_text
          address_distribution_email
          address_main_title
          address_main_text
          address_main_email
          address_support_title
          address_support_text
          address_support_email
        }
      }
    }
  }
`;
